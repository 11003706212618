// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
ActiveStorage.start()

// el-transition for page element animation
require('el-transition')

// Stimulus
import "controllers"

// Flatpickr
require("flatpickr")

// chart.js
require('chart.js')

// video.js
//require("video.js")
import videojs from 'video.js';
global.videojs = videojs;

// local-time
import LocalTime from "local-time"
LocalTime.start();

// import stylesheets
import '../stylesheets/application.scss'

// custom js code goes here
require('../custom.js')

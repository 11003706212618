import { Controller } from "stimulus"

export default class extends Controller {
    connect() {
        let step = this.data.get('current');

        if (step > 3) {
            this.element.scrollTo(this.element.offsetWidth, 0)
        }

        // let targ = document.getElementById('nav_step_'+step);
        // if (targ) {
        //     targ.scrollIntoView({behavior: 'smooth'});
        // }
    }
}

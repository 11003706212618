import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ 'trigger', 'content' ]

    connect() {
        this.triggerTargets.forEach((ele, idx)=>{
            //ele.preventDefault();
            //console.log(ele)
            ele.addEventListener('click', (e)=>{
                e.preventDefault();
                //console.log(e.target.dataset.target)
                //console.log(this.contentTargets[idx])
                //e.target.classList.add('active')
                this.activateTab(e.target.dataset.target);
            });
        });
    }

    activateTab(name) {
        this.triggerTargets.forEach((ele)=>{
            if (ele.dataset.target === name) {
                ele.classList.add('active');
            } else {
                ele.classList.remove('active');
            }
        });

        this.contentTargets.forEach((targ)=>{
            if (targ.id === name) {
                targ.classList.remove('hidden');
            } else {
                targ.classList.add('hidden');
            }
        })
        //this.contentTargets[idx].classList.remove('hidden');
    }
}

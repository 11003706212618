const fpickers = [];

document.addEventListener('turbo:load', function(){
    // page load actions here
    // get current URL path and assign 'active' class
    let pathname = window.location.pathname;
    let nav = document.querySelectorAll("a[href='"+ pathname +"']");
    nav.forEach(e => {
        e.classList.add('active');
    });

    // flatpicker
    const pickers = document.querySelectorAll('.datepicker');
    if (pickers.length > 0) {
        pickers.forEach((picker)=>{
            const fp = flatpickr(picker, {
                allowInput: true,
                altInput: true,
                altFormat: "F j, Y",
                dateFormat: "Y-m-d"
            });
            fpickers.push(fp);
        });
    }

    const year_pickers = document.querySelectorAll('.yearpicker');
    if (year_pickers.length > 0) {
        year_pickers.forEach((picker)=>{
            const fp = flatpickr(picker, {
                allowInput: true,
                altInput: true,
                altFormat: "Y",
                dateFormat: "Y-m-d"
            });
            fpickers.push(fp);
        });
    }

    // video-js
    let videos = document.querySelectorAll('.v-js');
    videos.forEach((vid)=>{
        let video = videojs(vid)
    });
});

document.addEventListener("turbo:before-cache", function() {
    // clean up elements here
    fpickers.forEach((fp)=>{
        fp.destroy();
    });

    let videos = document.querySelectorAll('.v-js');
    videos.forEach((vid)=>{
        let video = videojs(vid)
        video.dispose();
    });
});
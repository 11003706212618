import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ 'body', 'button' ]

    //connect() {}

    close() {
        this.bodyTarget.remove();
    }
}

import tippy from 'tippy.js';
import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ 'file' ]

    connect() {
        this.fileTarget.addEventListener('change', (e)=>{
            const size_in_megabytes = e.target.files[0].size/1024/1024;
            if (size_in_megabytes > 5) {
                alert("Maximum file size is 5MB. Please choose a smaller file.");
                e.target.value = "";
            }
        });
    }
}

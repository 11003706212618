import { enter, leave, toggle } from 'el-transition'
import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ 'menu', 'button' ]

    connect() {

    }

    toggleMenu() {
        if (this.menuTarget.classList.contains('hidden')) {
            enter(this.menuTarget);
        } else {
            leave(this.menuTarget);
        }
    }

    hideMenu(event) {
        const buttonClicked = this.buttonTarget.contains(event.target)

        if (!buttonClicked) {
            leave(this.menuTarget)
        }
    }
}

import { enter, leave, toggle } from 'el-transition'
import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ 'button', 'menu', 'overlay' ]

    connect() {
        this.mm_button = document.getElementById('mobile_menu_button');
        this.mm_button.onclick = (e) => {
            e.preventDefault();
            enter(this.menuTarget);
            enter(this.overlayTarget);
        }
    }

    toggleMenu() {
        if (this.menuTarget.classList.contains('hidden')) {
            enter(this.menuTarget);
            enter(this.overlayTarget);
        } else {
            leave(this.menuTarget);
            leave(this.overlayTarget);
        }
    }
}

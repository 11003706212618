// constants
const cb_rate = 0.9235;
const sscb_limit = 137700.00;
const sst_rate = 0.124;
const mt_rate = 0.029;
const amt_adj = 200000;
const amt_adj_couple = 250000;
const niit_adj = 200000;
const niit_adj_couple = 250000;
const cat_threshold = 203341;
const cat_threshold_couple = 406687;
const cat_div = 2500;
const cat_multi = 6;
const cat_t1 = 124;
const std_ded_base = 12400.00;
const solo401k_s5 = 19500;
const solo401k_s6 = 26000;
const qbi_floor = 163300;
const qbi_roof = 213300;
const qbi_phase_range = 50000;

// fit
const fit_rates = [10, 12, 22, 24, 32, 35, 37];
const fit_brackets = [9875, 40125, 85525, 163300, 207350, 518400, Infinity];
const fit_brackets_mc = [19750, 80250, 171050, 326600, 414700, 622050, Infinity];

// california
const cat_rates = [1.0, 2.0, 4.0, 6.0, 8.0, 9.3, 10.3, 11.3, 12.3];
const cat_brackets = [8932, 21175, 33421, 46394, 58634, 299508, 359407, 599012, Infinity];
const cat_brackets_mc = [17864, 42350, 66842, 92788, 117268, 599016, 718814, 1198024, Infinity];

// florida
const flt_rates = [];
const flt_brackets = [];
const flt_brackets_mc = [];

// georgia
const gat_rates = [1.0, 2.0, 3.0, 4.0, 5.0, 5.75];
const gat_brackets = [750, 2250, 3750, 5250, 7000, Infinity];
const gat_brackets_mc = [1000, 3000, 5000, 7000, 10000, Infinity];
const ga_net_worth_brackets = [100000, 150000, 200000, 300000, 500000, 750000, 1000000, 2000000, 4000000, 6000000, 8000000, 10000000, 12000000, 14000000, 16000000, 18000000, 20000000, 22000000, Infinity];
const ga_net_worth_tax =[125, 150, 200, 250, 300, 500, 750, 1000, 1250, 1500, 1750, 2000, 2500, 3000, 3500, 4000, 4500, 5000, 5000];

// hawaii
const hit_rates = [1.4, 3.2, 5.5, 6.4, 6.8, 7.2, 7.6, 7.9, 8.25, 9.0, 10.0, 11.0];
const hit_brackets = [2400, 4800, 9600, 14400, 19200, 24000, 36000, 48000, 150000, 175000, 200000, Infinity];
const hit_brackets_mc = [4800, 9600, 19200, 28800, 38400, 48000, 72000, 96000, 300000, 350000, 400000, Infinity];

// illinois
const ilt_rates = [4.95];
const ilt_brackets = [Infinity];
const ilt_brackets_mc = [Infinity];

// massachusetts
const mat_rates = [1.0, 2.0, 4.0, 6.0, 8.0, 9.3, 10.3, 11.3, 12.3];
const mat_brackets = [8932, 21175, 33421, 46394, 58634, 299508, 359407, 599012, Infinity];
const mat_brackets_mc = [17864, 42350, 66842, 92788, 117268, 599016, 718814, 1198024, Infinity];

// maryland
const mdt_rates = [2.0, 3.0, 4.0, 4.75, 5.00, 5.25, 5.50, 5.75];
const mdt_brackets = [1000, 2000, 3000, 100000, 125000, 150000, 250000, Infinity];
const mdt_brackets_mc = [1000, 2000, 3000, 150000, 175000, 225000, 300000, Infinity];
const md_exmp_brackets = [100000, 125000, 150000, 175000, 200000, Infinity];
const md_exmp_amounts = [3200, 1600, 800, 0, 0, 0];
const md_exmp_amounts_mc = [3200, 3200, 3200, 1600, 800, 0];

// nevada
const nvt_rates = [];
const nvt_brackets = [];
const nvt_brackets_mc = [];

// north carolina
const nct_rates = [5.25];
const nct_brackets = [Infinity];
const nct_brackets_mc = [Infinity];

// new jersey
const njt_rates = [1.4, 1.75, 3.5, 5.525, 6.37, 8.97, 10.75];
const njt_rates_mc = [1.4, 1.75, 2.45, 3.5, 5.525, 6.37, 8.97, 10.75];
const njt_brackets = [20000, 35000, 40000, 75000, 500000, 1000000, Infinity];
const njt_brackets_mc = [20000, 50000, 70000, 80000, 150000, 500000, 1000000, Infinity];

// new york
const nyt_rates = [4.0, 4.5, 5.25, 5.9, 6.09, 6.41, 6.85, 8.82];
const nyt_brackets = [8500, 11700, 13900, 21400, 80650, 215400, 1077550, Infinity];
const nyt_brackets_mc = [17150, 23600, 27900, 43000, 161550, 323200, 2155350, Infinity];
const nyt_amounts = [0, 340, 484, 600, 1042, 4650, 13288, 72345];
const nyct_rates = [3.078, 3.762, 3.819, 3.876];
const nyct_brackets = [12000, 25000, 50000, Infinity];
const nyct_brackets_mc = [21600, 45000, 90000, Infinity];
const ny_fran_brackets = [100000, 250000, 500000, 1000000, 5000000, 25000000];
const ny_fran_amounts = [25, 50, 175, 300, 1000, 3000, 4500];
const ny_gross_amounts = [25, 75, 175, 500, 1500, 3500, 5000];

// pennsylvania
const pat_rates = [3.07];
const pat_brackets = [Infinity];
const pat_brackets_mc = [Infinity];

// texas
const txt_rates = [];
const txt_brackets = [];
const txt_brackets_mc = [];

// virginia
const vat_rates = [2.0, 3.0, 5.0, 5.75];
const vat_brackets = [3000, 5000, 17000, Infinity];
const vat_brackets_mc = [3000, 5000, 17000, Infinity];

// washington
const wat_rates = [];
const wat_brackets = [];
const wat_brackets_mc = [];

// state deduction
const state_ca_ded = 4601.00;
const state_fl_ded = 0.00;
const state_ga_ded = 7300.00;
const state_ga_ded_mc = 13400.00;
const state_hi_ded = 3344.00;
const state_il_ded = 0.00;
const state_ma_ded = 6400.00;
const state_md_ded = 2300.00;
const state_md_ded_mc = 5850.00;
const state_nc_ded = 10750.00;
const state_nj_ded = 1000.00;
const state_nv_ded = 0.00;
const state_ny_ded = 8000.00;
const state_ny_ded_mc = 16050.00;
const state_pa_ded = 0.00;
const state_tx_ded = 0.00;
const state_va_ded = 5430.00;
const state_wa_ded = 0.00;

export default class TaxCalc {

    //mode;

    // constructor(mode) {
    //     this.mode = mode;
    // }

    set mode(value) {
        this._mode = value;
        this.mmp = (this._mode === 'single') ? 1 : 2;
    }

    set state(value) {
        this._state = value;

        // set state deduction and special cases
        switch (value) {
            case 'ga':
                if (this._mode === 'single') this.state_deduction = state_ga_ded;
                else this.state_deduction = state_ga_ded_mc;
                this.state_rates = gat_rates;
                break;
            case 'il':
                this.state_deduction = state_il_ded;
                this.state_rates = ilt_rates;
                break;
            case 'md':
                if (this._mode === 'single') this.state_deduction = state_md_ded;
                else this.state_deduction = state_md_ded_mc;
                this.state_rates = mdt_rates;
                break;
            case 'nj':
                this.state_deduction = state_nj_ded;
                this.state_rates = (this._mode === 'single') ? njt_rates : njt_rates_mc;
                break;
            case 'ny':
                this.state_deduction = (this._mode === 'single') ? state_ny_ded : state_ny_ded_mc;
                this.state_rates = nyt_rates;
                break;
            default:
                this.state_deduction = eval('state_' + value + '_ded');
                this.state_rates = eval(value + 't_rates');
        }

        // set state rates and brackets
        this.state_brackets = eval(value + 't_brackets');
        this.state_brackets_mc = eval(value + 't_brackets_mc');
        //console.log(this.state_deduction)
    }

    calcSet(income) {
        let sei = income;
        let seicb = sei * cb_rate;
        let sscb = Math.min(seicb, sscb_limit);
        let sst = sscb * sst_rate;
        let mcb = seicb;
        let mt = seicb * mt_rate;
        let set = sst + mt;
        //let hse = set / 2;

        set = Math.round(set)
        // console.log('set: '+set)

        // store results
        this.total_income = sei;
        this.cal_base = seicb;
        this.se_tax = set;

        return set
    }

    calcCorpSet(income) {
        let sei = income;
        let seicb = sei;
        let sscb = Math.min(seicb, sscb_limit * this.mmp);
        let sst = sscb * sst_rate;
        let mcb = seicb;
        let mt = seicb * mt_rate;
        let set = sst + mt;

        set = Math.round(set);

        // store results
        //this.total_income = sei;
        this.cal_base = seicb;
        this.se_tax = set;

        //return set
    }

    calcSchE(scenario, income, w2) {
        //let mmp = (this.mode === 'single') ? 1 : 2;

        //let p_tax = Math.round(-w2 * (7.65 / 100));
        let p_tax = -Math.round(this.se_tax / 2);
        let futa = 0.0;

        // calc futa
        switch (this._state) {
            case 'ga':
                futa = -(299 * this.mmp);
                break;
            case 'hi':
                futa = -Math.round( (42 * this.mmp) + (Math.min(w2, 48100 * this.mmp) * 0.024) + (Math.min(w2, 48100 * this.mmp) * 0.0001) );
                break;
            case 'il':
                futa = -(440 * this.mmp);
                break;
            case 'md':
                futa = -(263 * this.mmp);
                break;
            case 'nc':
                futa = -Math.round((42 * this.mmp) + (Math.min(w2, 25200 * this.mmp) * 0.01));
                break;
            case 'nj':
                futa = -Math.round((42 * this.mmp) + (Math.min(w2, 35300 * this.mmp) * 0.026825) + (Math.min(w2, 35300 * this.mmp) * 0.005) + (Math.min(w2, 35300 * this.mmp) * 0.001175));
                break;
            case 'ny':
                futa = -(518 * this.mmp);
                break;
            case 'pa':
                // (7000*0.6%*1)-(10000*3.689%*1) = 411
                futa = -(411 * this.mmp);
                break;
            case 'va':
                futa = -(243 * this.mmp);
                break;
            case 'tx':
            case 'nv':
            case 'wa':
            case 'fl':
                // no futa
                break;
            case 'ca':
            default:
                futa = -287 * this.mmp;
        }
        // console.log('futa '+futa)

        let sum = income + (-w2) + p_tax + futa;
        //let res_sum = Math.round(sum * 0.015);

        // console.log('ptax '+p_tax)
        // console.log('sum '+sum)

        let state_tax = 0.00;
        let profit_sharing = 0.00;

        // get profit sharing/defined benefit
        switch (scenario) {
            case 4:
                break;
            case 5:
                // 57000 - 19500(solo401k) = 37500
                profit_sharing = -Math.round(Math.min((w2 * 0.25), (37500.00 * this.mmp)));
                break;
            case 6:
                profit_sharing = -Math.min(w2, (230000 * this.mmp));
                break;
        }

        // get state tax
        switch (this._state) {
            case 'ga':
                // net worth tax
                state_tax = -this.calcNetWorthTax(sum + profit_sharing, ga_net_worth_brackets, ga_net_worth_tax);
                // 9500*2.7% = 257
                this.corp_fee = Math.abs(state_tax) + (257 * this.mmp);
                break;
            case 'hi':
                // no state tax
                this.corp_fee = Math.round(Math.min(Math.abs(w2), 48100 * this.mmp) * 0.024 + Math.min(Math.abs(w2), 48100 * this.mmp) * 0.0001);
                break;
            case 'pa':
                // no state tax
                // (10000*3.689%*1) = 368.9
                this.corp_fee = 369 * this.mmp;
                break;
            case 'il':
                state_tax = -Math.round((sum + profit_sharing) * 0.015);
                // (12740*3.125%*1) = 398
                this.corp_fee = Math.abs(state_tax) + (398 * this.mmp);
                break;
            case 'md':
                // no state tax
                this.corp_fee = 221 * this.mmp;
                break;
            case 'nc':
                state_tax = -Math.max(200, ( 200 + Math.round(((sum + profit_sharing) - 1000000) / 1500 )));
                this.corp_fee = Math.abs(state_tax) + ( Math.min(25200 * this.mmp, w2) * 0.01 );
                break;
            case 'nj':
                // no state tax
                this.corp_fee = Math.round(Math.min(w2, 35300 * this.mmp) * 0.026825 + Math.min(w2, 35300 * this.mmp) * 0.005 + Math.min(w2, 35300 * this.mmp) * 0.001175);
                break;
            case 'ny':
                let corp_income = sum + profit_sharing;
                let fran_tax = this.calcProgressiveAmount(corp_income, ny_fran_brackets, ny_fran_amounts);
                // console.log('fran: '+fran_tax)
                let gen_tax = this.calcNyGenCorpTax(corp_income, income, w2);
                // console.log('gen_corp: '+gen_tax)
                state_tax = -(fran_tax + gen_tax);
                this.corp_fee = fran_tax + (476 * this.mmp) + gen_tax;
                break;
            case 'va':
                // no state tax
                this.corp_fee = (this._mode === 'single') ? 257 : 402;
                break;
            case 'tx':
            case 'nv':
            case 'wa':
            case 'fl':
                // no state tax, corp fee, etc
                this.corp_fee = 0.00;
                break;
            case 'ca':
            default:
                switch (scenario) {
                    case 4:
                        state_tax = -Math.max(Math.round(sum * 0.015), 800);
                        break;
                    case 5:
                    case 6:
                        state_tax = -Math.max(Math.round((sum + profit_sharing) * 0.015), 800);
                        break;
                }

                this.corp_fee = Math.abs(state_tax) + (245 * this.mmp);
        }
        // console.log('pf '+profit_sharing)
        // console.log('st '+state_tax)

        this.profit_sharing = profit_sharing;
        // 7000*3.4%*1+7000*0.1%*1 = 245

        let sche = income + (-w2) + p_tax + futa + state_tax + profit_sharing;
        // console.log('sche: '+sche)
        return sche
    }

    calcStats(scenario, w2, sch_c, sch_e, k1, hse, adj) {
        // multiplier for single or married
        //let mmp = (this.mode === 'single') ? 1 : 2;
        let _amt_adj = amt_adj;
        let _niit_adj = niit_adj;
        let _cat_thd = cat_threshold;
        let _fit_brackets = fit_brackets;
        let _state_brackets = this.state_brackets;
        if (this._mode === 'single') {

        } else {
            //mmp = 2;
            _amt_adj = amt_adj_couple;
            _niit_adj = niit_adj_couple;
            _cat_thd = cat_threshold_couple;
            _fit_brackets = fit_brackets_mc;
            _state_brackets = this.state_brackets_mc;
        }

        let std_ded = std_ded_base * this.mmp;

        //let hse = this.se_tax / 2;
        let ow2 = w2;
        let solo401k = 0;
        if (scenario >= 5) {
            solo401k = (scenario === 5) ? solo401k_s5 : solo401k_s6;
            solo401k *= this.mmp;
            w2 = w2 - solo401k;
        }

        let agi = (w2 + sch_c + sch_e + k1 + (-hse) + (-adj));

        let qb_income = this.getQbIncome(scenario, sch_c, hse, sch_e, adj);

        let qbi = 0.00;
        if ((agi - std_ded) <= (qbi_floor * this.mmp)) {
            // console.log('sce'+scenario+' qbi income: '+qb_income)
            qbi = this.calcQbi(qb_income, agi, std_ded);
        } else {
            // console.log('sce'+scenario+' qbiA income: '+qb_income)
            qbi = this.calcQbiA(qb_income, agi, std_ded);
        }

        let se_tax = this.se_tax;
        if (scenario < 4)  se_tax *= this.mmp;

        let taxable_income = agi - std_ded - qbi;
        if (taxable_income < 0) taxable_income = 0;

        //let cat_income = agi - (this.state_deduction * this.mmp);
        let fit = this.calcFit(taxable_income, _fit_brackets);
        let amt = this.calcAmt(scenario, this.cal_base, _amt_adj);
        let niit = this.calcNiit(scenario, k1, agi, _niit_adj);

        // get state income tax
        let state_income = 0.0;
        let cat = 0.0;
        // get state exemption
        let state_exmp = 0.0;

        // sdi
        let sdi = 0.0;

        switch (this._state) {
            case 'ga':
                state_income = agi - this.state_deduction;
                break;
            case 'hi':
                if (scenario > 3 && this._mode === 'single') state_exmp = this.calcCatExmp(agi, _cat_thd, cat_t1 * this.mmp);
                sdi = Math.min((ow2 * 0.005), (11.03 * this.mmp));
                state_income = agi - (this.state_deduction * this.mmp);
                break;
            case 'il':
                let il_state_exmp = (agi <= 250000) ? 2325 * this.mmp : 0;
                if (scenario < 4 && this._mode === 'couple') state_income = sch_c - il_state_exmp;
                else state_income = agi - il_state_exmp;
                break;
            case 'md':
                let amounts = (this._mode === 'single') ? md_exmp_amounts : md_exmp_amounts_mc;
                let md_state_exmp = this.calcProgressiveAmount(agi, md_exmp_brackets, amounts) * this.mmp;
                state_income = agi - this.state_deduction - md_state_exmp;
                break;
            case 'nc':
                if (this._mode === 'single') {
                    state_income = agi - this.state_deduction;
                } else {
                    let income = (scenario > 3) ? agi : sch_c;
                    let nc_state_exmp = (income <= 250000) ? 4650 : 0;
                    state_income = income - (this.state_deduction * this.mmp) - nc_state_exmp;
                }
                break;
            case 'nj':
                let income = (scenario > 3) ? agi : sch_c;
                state_income = income - (this.state_deduction * this.mmp)
                sdi = Math.round(Math.min(w2, 35300) * 0.003825 + Math.min(w2, 134900) * 0.0026 + Math.min(w2, 35300) * 0.000425 + Math.min(w2, 134900) * 0.0016);
                break;
            case 'ny':
                state_income = agi - this.state_deduction;
                break;
            case 'pa':
                sdi = ow2 * 0.0006;
                if (scenario > 3) state_income = w2 + sch_e;
                else state_income = sch_c;
                break;
            case 'va':
                state_exmp = 259;
                state_income = agi - (this.state_deduction * this.mmp);
                break;
            case 'tx':
            case 'nv':
            case 'wa':
            case 'fl':
                // no state tax, corp fee, etc
                state_exmp = 0.00;
                state_income = 0.00;
                break;
            case 'ca':
            default:
                // defaults to ca
                state_exmp = this.calcCatExmp(agi, _cat_thd, cat_t1 * this.mmp);
                sdi = Math.min((ow2 * 0.01), (1229.09 * this.mmp));
                state_income = agi - (this.state_deduction * this.mmp);
        }
        // console.log('sdi: '+sdi)
        // console.log('agi: '+agi)
        // console.log('state_ded: '+this.state_deduction)
        // console.log('state_exmp: '+state_exmp)
        // console.log('state_income: '+state_income)

        let nyt = 0.0;
        if (this._state === 'ny') {
            cat = this.calcNyIncomeTax(agi, state_income);
            this.state_rates = nyct_rates;
            nyt = this.calcCat(state_income, 0, nyct_brackets);
            this.state_rates = nyt_rates;
        } else {
            cat = this.calcCat(state_income, state_exmp, _state_brackets);
        }

        let total_tax = 0.00;
        let contribution = 0.00;
        let amount_save = 0.00;
        let percent_save = 0.00;
        let other_tax = 0.00;
        let corp_fee = 0.00;

        switch (scenario) {
            case 6:
                contribution = this.profit_sharing - solo401k;
                other_tax = this.calcOtherTax(sdi);
                corp_fee = this.corp_fee;
                total_tax = Math.round(se_tax + fit + amt + niit + cat + nyt + other_tax + corp_fee);
                amount_save = total_tax - this.s1_total_tax;
                percent_save = Math.round((Math.abs(amount_save) / this.s1_total_tax) * 100);
                break;
            case 5:
                contribution = this.profit_sharing - solo401k;
                other_tax = this.calcOtherTax(sdi);
                corp_fee = this.corp_fee;
                total_tax = Math.round(se_tax + fit + amt + niit + cat + nyt + other_tax + corp_fee);
                amount_save = total_tax - this.s1_total_tax;
                percent_save = Math.round((Math.abs(amount_save) / this.s1_total_tax) * 100);
                break;
            case 4:
                other_tax = this.calcOtherTax(sdi);
                corp_fee = this.corp_fee;
                total_tax = Math.round(se_tax + fit + amt + niit + cat + nyt + other_tax + corp_fee);
                amount_save = total_tax - this.s1_total_tax;
                percent_save = Math.round((Math.abs(amount_save) / this.s1_total_tax) * 100);
                break;
            case 2:
            case 3:
                contribution = -adj;
                total_tax = Math.round(se_tax + fit + amt + niit + cat + nyt);
                amount_save = total_tax - this.s1_total_tax;
                percent_save = Math.round((Math.abs(amount_save) / this.s1_total_tax) * 100);
                break;
            case 1:
            default:
                total_tax = Math.round(se_tax + fit + amt + niit + cat + nyt);
                this.s1_total_tax = total_tax;
        }

        // update saved total tax
        this.prev_total_tax = total_tax;

        let dis_income = (this.total_income * this.mmp) - total_tax + contribution;

        return {
            se_tax: se_tax,
            agi: agi,
            qbi: qbi,
            taxable_income: taxable_income,
            fit: fit,
            amt: amt,
            niit: niit,
            cat: cat,
            nyt: nyt,
            other_tax: other_tax,
            corp_fee: corp_fee,
            total_tax: total_tax,
            contribution: contribution,
            dis_income: dis_income,
            amount_save: amount_save,
            percent_save: percent_save
        }
    }

    getQbIncome(scenario, sch_c, hse, sch_e, adj) {
        let res = 0.00;

        switch (scenario) {
            case 4:
            case 5:
            case 6:
                res = sch_e;
                break;
            case 3:
                res = sch_c - hse - adj;
                break;
            case 1:
            case 2:
            default:
                res = sch_c - hse;
        }

        return res
    }

    calcQbi(income, agi, std_ded) {
        let qbi_comp = income * (20/100);
        let income_limit = (agi - std_ded) * (20/100);
        let qbi = Math.min(qbi_comp, income_limit);
        return Math.round(qbi)
    }

    calcQbiA(income, agi, std_ded) {
        let qbi_comp = income * (20/100);

        // calc part III
        let qbi_after_red = 0.00;
        let income_b4_qbi = agi - std_ded;
        // console.log('income '+income)
        // console.log('income b4 qbi '+income_b4_qbi)
        if (income_b4_qbi > qbi_floor && income_b4_qbi < qbi_roof) {
            let p3_22 = income_b4_qbi - qbi_floor;
            let phase_percent = p3_22 / qbi_phase_range;
            let phase_total = Math.round(qbi_comp * phase_percent);
            qbi_after_red = Math.round(qbi_comp - phase_total);
            // console.log('p3_22 '+p3_22)
            // console.log('phase_percent '+phase_percent)
            // console.log('phase_total '+phase_total)
            // console.log('qbi after_red '+qbi_after_red)
        }

        let income_limit = (agi - std_ded) * (20/100);
        let qbi = Math.min(qbi_after_red, income_limit);
        return Math.round(qbi)
    }

    calcFit(income, _fit_brackets) {
        let tax = 0.00;
        fit_rates.some((rate, index)=>{
            let bracket = _fit_brackets[index];
            let prev_bracket = (index === 0) ? 0 : _fit_brackets[index - 1];
            let bracket_tax = 0.00;
            //console.log(income)

            if (income > bracket) {
                bracket_tax = Math.round((bracket - prev_bracket) * (rate / 100));
                tax += bracket_tax;
                // console.log(prev_bracket);
                // console.log(bracket_tax);
            } else {
                bracket_tax = Math.round(Math.max(0, (income - prev_bracket)) * (rate / 100));
                tax += bracket_tax;
                // console.log('-')
                // console.log(prev_bracket);
                // console.log(bracket_tax);
                // console.log('-')
                return true
            }
        })

        return tax
    }

    calcAmt(scenario, income, adj) {
        if (scenario < 4) {
            income *= this.mmp;
        }

        let amtcb = income - adj;
        let amt = 0.00;
        if (amtcb > 0) {
            amt = Math.round(amtcb * (0.9 / 100));
        }

        return amt
    }

    calcNiit(scenario, k1, agi, niit_adj) {
        let cb1 = k1;
        let cb2 = agi - niit_adj;
        let niit = 0.00;
        if (cb1 > 0 && cb2 > 0) {
            niit = Math.round(Math.min(cb1, cb2) * (3.8 / 100));
        }
        return niit
    }

    calcCat(state_income, exmp, _state_brackets) {
        let cat = 0.00;

        this.state_rates.some((rate, index)=>{
            let bracket = _state_brackets[index];
            let prev_bracket = (index === 0) ? 0 : _state_brackets[index - 1];
            let bracket_tax = 0.00;

            if (state_income > bracket) {
                bracket_tax = Math.round((bracket - prev_bracket) * (rate / 100));
                cat += bracket_tax;
                // console.log(prev_bracket);
                // console.log(bracket_tax);
            } else {
                bracket_tax = Math.round(Math.max(0, (state_income - prev_bracket)) * (rate / 100));
                cat += bracket_tax;
                // console.log(rate)
                // console.log(prev_bracket);
                // console.log(bracket_tax);
                return true
            }
        })

        if (this._state === 'ca') {
            let mental = (state_income > 1000000) ? Math.round((state_income - 1000000) * 0.01) : 0;
            cat += mental;
        }

        // console.log(state_income)
        // console.log(cat)
        // console.log(exmp)
        cat -= exmp;

        return cat
    }

    calcNetWorthTax(corp_income, net_worth_brackets, net_worth_tax) {
        let gat = 0.00;

        net_worth_tax.some((amount, index)=>{
            let bracket = net_worth_brackets[index];
            let prev_amount = (index === 0) ? 0 : net_worth_tax[index - 1];

            if (corp_income > bracket) {
                // = continue in some()
                return false
            } else {
                gat = prev_amount;
                // console.log(gat)
                // console.log(prev_bracket);
                // console.log(bracket_tax);
                // = break in some()
                return true
            }
        })

        return gat
    }

    calcNyGenCorpTax(sum, income, w2) {
        let tax_a = sum * 0.0885;
        let tax_b = (sum / 2) * 0.0015;
        let tax_c = (((sum + w2) - 40000) * 0.15) * 0.0885;
        let tax_d = this.calcProgressiveAmount(income, ny_fran_brackets, ny_gross_amounts);

        let res = Math.round(Math.max(tax_a, tax_b, tax_c, tax_d));
        if (res > 1000) res = Math.round(res * 1.25);
        return res
    }

    calcNyIncomeTax(agi, taxable_income) {
        let res = 0.00;
        let l_3, l_4, l_5, l_6, l_7, l_8, l_9, l_10;

        // console.log('agi: '+agi)
        // console.log('tax_income: '+taxable_income)

        switch (true) {
            case agi > 1077550:
                // chart e
                // console.log('chart e')
                l_3 = taxable_income * 0.0882;
                if (agi > 1127550) {
                    res = Math.round(l_3);
                } else {
                    l_4 = this.calcCat(taxable_income, 0, nyt_brackets);
                    l_5 = Math.max(l_3 - l_4, 0);
                    l_6 = (taxable_income <= 215400) ? 519 : 1467;
                    l_7 = Math.max(l_5 - l_6, 0);
                    l_8 = agi - 1077550;
                    l_9 = l_8 / 50000;
                    l_10 = Math.round(l_7 * l_9);
                    res = Math.round(l_4 + l_6 + l_10);
                    // console.log('l3: '+l_3)
                    // console.log('l4: '+l_4)
                    // console.log('l5: '+l_5)
                    // console.log('l6: '+l_6)
                    // console.log('l7: '+l_7)
                    // console.log('l8: '+l_8)
                    // console.log('l9: '+l_9)
                    // console.log('l10: '+l_10)
                }
                break;
            case agi > 215400 && taxable_income > 215400:
                // chart d
                // console.log('chart d')
                l_3 = taxable_income * 0.0685;
                if (agi > 265400) {
                    res = Math.round(l_3);
                } else {
                    l_4 = this.calcCat(taxable_income, 0, nyt_brackets);
                    l_5 = Math.max(l_3 - l_4, 0);
                    l_6 = 519;
                    l_7 = Math.max(l_5 - l_6, 0);
                    l_8 = agi - 215400;
                    l_9 = l_8 / 50000;
                    l_10 = Math.round(l_7 * l_9);
                    res = Math.round(l_4 + l_6 + l_10);
                    // console.log('l3: '+l_3)
                    // console.log('l4: '+l_4)
                    // console.log('l5: '+l_5)
                    // console.log('l6: '+l_6)
                    // console.log('l7: '+l_7)
                    // console.log('l8: '+l_8)
                    // console.log('l9: '+l_9)
                    // console.log('l10: '+l_10)
                }
                break;
            case agi > 107650 && taxable_income <= 215400:
                // chart c
                // console.log('chart c')
                l_3 = taxable_income * 0.0641;
                if (agi > 157650) {
                    res = Math.round(l_3);
                } else {
                    l_4 = this.calcCat(taxable_income, 0, nyt_brackets);
                    l_5 = Math.max(l_3 - l_4, 0);
                    l_6 = agi - 107650;
                    l_7 = l_6 / 50000;
                    l_8 = Math.round(l_5 * l_7);
                    res = Math.round(l_4 + l_8);
                    // console.log('l4: '+l_4)
                    // console.log('l5: '+l_5)
                    // console.log('l6: '+l_6)
                    // console.log('l7: '+l_7)
                    // console.log('l8: '+l_8)
                }
                break;
            case agi < 107650:
            default:
                // chart f
                // console.log('chart f')
                res = this.calcCat(taxable_income, 0, nyt_brackets);
        }
        // console.log('res: '+res)

        return res
    }

    calcProgressiveAmount(income, brackets, amounts) {
        let res = 0.00;

        amounts.some((amount, index)=>{
            let bracket = brackets[index];

            if (income > bracket) {
                // = continue in some()
                return false
            } else {
                res = amount;
                // console.log(res)
                // = break in some()
                return true
            }
        })

        return res
    }

    calcCatExmp(agi, cat_threshold, t1) {
        let c1 = Math.max((agi - cat_threshold), 0);
        let d1 = Math.round(Math.round(c1 / cat_div) * cat_multi);
        //let t1 = 124;
        //let exmp = t1 - d1;
        let exmp = Math.max(0, t1 - d1);
        // console.log(c1)
        // console.log(d1)
        // console.log(exmp)
        return exmp
    }

    calcOtherTax(sdi) {
        let futa = 0.00;

        switch (this._state) {
            case 'tx':
            case 'nv':
            case 'wa':
            case 'fl':
                // no futa
                break;
            case 'ca':
            default:
                futa = 42.00 * this.mmp;
        }

        //let sdi = Math.min((income * 0.01), (1229.09 * this.mmp));
        let res = Math.round(futa + sdi);
        return res
    }
}
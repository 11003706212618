import { enter, leave, toggle } from 'el-transition'
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
      'noPlan', 'currentPlan', 'prevPlan', 'detail',
      'currentSection', 'prevSection',
      'otherAffiliation', 'otherAffiliationDetail', 'otherSponsor', 'otherAffCheck'
  ]

  connect() {
    this.currentPlanTarget.addEventListener('change', ()=>{ this.togglePlan('current'); })
    this.prevPlanTarget.addEventListener('change', ()=>{ this.togglePlan('prev'); })

    if (this.currentPlanTarget.checked) this.currentSectionTarget.classList.remove('hidden');
    if (this.prevPlanTarget.checked) this.prevSectionTarget.classList.remove('hidden');

    this.detailTargets.forEach((targ) => {
      targ.setAttribute('data-transition-enter', 'transition ease-out duration-100');
      targ.setAttribute('data-transition-enter-start', 'transform opacity-0 scale-95');
      targ.setAttribute('data-transition-enter-end', 'transform opacity-100 scale-100');
      targ.setAttribute('data-transition-leave', 'transition ease-in duration-75');
      targ.setAttribute('data-transition-leave-start', 'transform opacity-100 scale-100');
      targ.setAttribute('data-transition-leave-end', 'transform opacity-0 scale-95');
    })
  }

  togglePlan(time) {
    let name = time + 'PlanTarget';
    let section = time + 'SectionTarget';
    let target = this[name];
    let checked = target.checked;
    if (checked) {
      // disable no plan option
      this.noPlanTarget.checked = false;
      this.noPlanTarget.disabled = true;

      enter(this[section]);
    } else {
      // un-disable if both unchecked
      let other = (time === 'current') ? 'prev' : 'current';
      let other_checked = this[other+'PlanTarget'].checked;
      if (!checked && !other_checked) {
        this.noPlanTarget.disabled = false;
      }

      leave(this[section])
    }
  }

  toggleOwnership(e) {
    //console.log(e.target.value)
    if (e.target.value === 'false') {
      this.otherAffCheckTarget.checked = true;
      leave(this.otherAffiliationDetailTarget);
    }
    toggle(this.otherAffiliationTarget);
  }

  toggleAffiliationDetail(e) {
    toggle(this.otherAffiliationDetailTarget);
  }

  toggleSponsor(e) {
    toggle(this.otherSponsorTarget);
  }
}

import tippy from 'tippy.js';
import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ 'trigger', 'desc' ]

    connect() {
        tippy(this.triggerTarget, {
            content: this.descTarget.innerHTML,
            allowHTML: true
        });
    }
}

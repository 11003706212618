import { enter, leave } from 'el-transition'
import { Controller } from "stimulus"
import Chart from 'chart.js/auto'

import TaxCalc from "../classes/tax_calc";

export default class extends Controller {
    static targets = [
        'mode', 'stateSel', 'stateLabel', 'detailNy',
        'detail', 'optionalDetail', 'savings', 'chart', 'btnUpdate', 'totalInput',
        'txtTotalIncome', 'txtW2', 'txtK1', 'labelW2',
        'sceSet', 'sceFit', 'sceAmt', 'sceNiit', 'sceCat', 'sceNyt', 'sceOther', 'sceCorp', 'sceTotalTop', 'sceTotal', 'sceCont', 'sceDis', 'sceAmountSave', 'scePercentSave',
        'sum1', 'sum2', 'sum3', 'sum4', 'sum5'
        // 's1Set', 's1Fit', 's1Amt', 's1Niit', 's1Cat', 's1Other', 's1Corp', 's1Total', 's1Cont', 's1Dis', 's1AmountSave', 's1PercentSave',
        // 's2Set', 's2Fit', 's2Amt', 's2Niit', 's2Cat', 's2Other', 's2Corp', 's2Total', 's2Cont', 's2Dis', 's2AmountSave', 's2PercentSave',
        // 's3Set', 's3Fit', 's3Amt', 's3Niit', 's3Cat', 's3Other', 's3Corp', 's2Total', 's2Cont', 's2Dis', 's2AmountSave', 's2PercentSave',
        // 's4Set', 's4Fit', 's4Amt', 's4Niit', 's4Cat', 's4Other', 's4Corp', 's2Total', 's2Cont', 's2Dis', 's2AmountSave', 's2PercentSave',
        // 's5Set', 's5Fit', 's5Amt', 's5Niit', 's5Cat', 's5Other', 's5Corp', 's2Total', 's2Cont', 's2Dis', 's2AmountSave', 's2PercentSave',
        // 's6Set', 's6Fit', 's6Amt', 's6Niit', 's6Cat', 's6Other', 's6Corp', 's2Total', 's2Cont', 's2Dis', 's2AmountSave', 's2PercentSave'
    ]

    connect() {
        //console.log(this.detailTargets.length)
        //console.log(this.btnUpdateTarget)
        this.is_open = false;
        this.mode = 'single';
        this.state = 'ca';
        this.taxCalc = new TaxCalc();
        this.taxCalc.mode = this.mode;
        this.taxCalc.state = this.state;
        this.hide_optional = false;
        this.optionalStates = ['tx', 'wa', 'nv', 'fl'];

        // bind mode toggle
        this.modeTarget.addEventListener('click', ()=>{
            if (this.mode === 'single') {
                this.mode = 'couple'
                this.modeTarget.innerHTML = 'Married Couple';
            } else {
                this.mode = 'single'
                this.modeTarget.innerHTML = 'Single';
            }

            this.taxCalc.mode = this.mode;
            this.taxCalc.state = this.state;
            this.updateTotalIncome();
        });

        // bind state select
        this.stateSelTarget.addEventListener('change', ()=>{
            this.state = this.stateSelTarget.value;
            this.taxCalc.state = this.state;
            this.updateTotalIncome();
            this.stateLabelTarget.innerHTML = this.state.toUpperCase();

            this.hide_optional = this.optionalStates.includes(this.state);
            this.toggleOptionalDetail();
        });

        // bind update button
        this.btnUpdateTarget.addEventListener('click', ()=>{this.updateTotalIncome()})
        this.totalInputTarget.addEventListener('change', ()=>{this.updateTotalIncome()})

        // bind animation for details
        this.detailTargets.forEach((targ) => {
            targ.setAttribute('data-transition-enter', 'transition ease-out duration-100');
            targ.setAttribute('data-transition-enter-start', 'transform opacity-0 scale-95');
            targ.setAttribute('data-transition-enter-end', 'transform opacity-100 scale-100');
            targ.setAttribute('data-transition-leave', 'transition ease-in duration-75');
            targ.setAttribute('data-transition-leave-start', 'transform opacity-100 scale-100');
            targ.setAttribute('data-transition-leave-end', 'transform opacity-0 scale-95');
        })

        // bind chart
        this.chart = new Chart(this.chartTarget, {
            type: 'bar',
            data: {
                labels: ['Scenario 1', 'Scenario 2', 'Scenario 3', 'Scenario 4', 'Scenario 5', 'Scenario 6'],
                datasets: [{
                    label: 'Savings ($1,000)',
                    data: [0, 1.71, 7.82, 6.21, 17.1, 31.36],
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(255, 159, 64, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(75, 192, 192, 0.2)'
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(255, 159, 64, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(75, 192, 192, 1)'
                    ],
                    borderWidth: 1
                }]
            },
            options: {
                scales: {
                    y: {
                        beginAtZero: true
                    }
                }
            }
        });
    }

    toggleDetail(e) {
        e.preventDefault();
        this.is_open = !this.is_open;

        this.detailTargets.forEach((targ) => {
            if (targ.classList.contains('hidden')) {
                //targ.classList.remove('hidden');
                enter(targ);
            } else {
                //targ.classList.add('hidden');
                leave(targ);
            }
        })

        this.toggleOptionalDetail();

        this.savingsTarget.scrollIntoView({behavior: 'smooth'});

        // hide nyc when necessary
        if (this.state === 'ny' && !this.is_open) this.detailNyTarget.classList.add('hidden');

        // hide optional details when necessary
        // if (this.optionalStates.includes(this.state)) {
        //     this.optionalDetailTargets.forEach((targ)=>{
        //         targ.classList.add('hidden');
        //     })
        // } else {
        //     this.optionalDetailTargets.forEach((targ)=>{
        //         targ.classList.remove('hidden');
        //     })
        // }
    }

    toggleOptionalDetail() {
        this.optionalDetailTargets.forEach((targ) => {
            if (this.is_open && !this.hide_optional) {
                //targ.classList.remove('hidden');
                //console.log(targ.dataset.optional !== 'true')
                enter(targ);
            } else {
                //targ.classList.add('hidden');
                leave(targ);
            }
        })
    }

    updateTotalIncome() {
        //console.log(this.totalInputTarget.value)
        let income = Math.round(parseFloat(this.totalInputTarget.value));
        this.totalInputTarget.value = 'Updating...';

        let w2 = Math.round(income * 0.4);
        let k1 = income - w2;
        this.txtTotalIncomeTarget.innerHTML = '$' + this.numberWithCommas(income);
        this.txtW2Target.innerHTML = '$' + this.numberWithCommas(w2);
        this.txtK1Target.innerHTML = '$' + this.numberWithCommas(k1);

        // calculate self employed tax (SET)
        let set = 0.00;
        let hse = 0.00;
        let s3_adj_mp = 1;
        let mmp = 1;
        if (this.mode === 'single') {
            set = this.taxCalc.calcSet(income);
            hse = set / 2;

            // update w2 label
            this.labelW2Target.innerHTML = 'W2';
        } else {
            // set as split between the couple
            set = this.taxCalc.calcSet(income / 2);
            hse = set;
            s3_adj_mp = 2;
            mmp = 2;

            // update w2 label
            this.labelW2Target.innerHTML = 'W2 x 2';
        }
        //let set = this.taxCalc.calcSet(income);
        //let hse = set / 2;
        //let s3_adj = Math.round((income - hse) * 0.2);
        let s3_adj = Math.min(Math.round((income - hse) * 0.2), 57000.00 * s3_adj_mp);

        // store and display SET
        //this.net_income = income;
        //this.set = Math.round(set);
        // this.seTaxTargets.forEach((ele)=>{
        //     ele.innerHTML = '$' + this.numberWithCommas(this.set);
        // });

        // ###### scenario 1 ######
        // get stats for scenarios
        let s1_stats = this.taxCalc.calcStats(1, 0, income, 0, 0, hse, 0);
        let s2_stats = this.taxCalc.calcStats(2, 0, income, 0, 0, hse, 6000 * mmp);
        let s3_stats = this.taxCalc.calcStats(3, 0, income, 0, 0, hse, s3_adj);

        // ###### corp scenario ######
        this.taxCalc.calcCorpSet(w2);
        let s4_sche = this.taxCalc.calcSchE(4, income, w2);
        let s4_stats = this.taxCalc.calcStats(4, w2, 0, s4_sche, 0, 0, 0);
        let s5_sche = this.taxCalc.calcSchE(5, income, w2);
        let s5_stats = this.taxCalc.calcStats(5, w2, 0, s5_sche, 0, 0, 0);
        let s6_sche = this.taxCalc.calcSchE(6, income, w2);
        let s6_stats = this.taxCalc.calcStats(6, w2, 0, s6_sche, 0, 0, 0);
        // console.log(s5_sche)
        // console.log(s5_stats)

        this.updateStats(0, s1_stats);
        this.updateStats(1, s2_stats);
        this.updateStats(2, s3_stats);
        this.updateStats(3, s4_stats);
        this.updateStats(4, s5_stats);
        this.updateStats(5, s6_stats);

        // update sum numbers
        let sum1 = s4_stats.total_tax - s1_stats.total_tax;
        this.sum1Target.innerHTML = '$' + this.numberWithCommas(sum1);

        let sum2 = s5_stats.total_tax - s4_stats.total_tax;
        this.sum2Target.innerHTML = '$' + this.numberWithCommas(sum2);

        let sum3 = s6_stats.total_tax - s4_stats.total_tax;
        this.sum3Target.innerHTML = '$' + this.numberWithCommas(sum3);

        let sum4 = Math.round((sum2 / s5_stats.contribution) * 10000);
        this.sum4Target.innerHTML = '$' + this.numberWithCommas(sum4);

        let sum5 = Math.round((sum3 / s6_stats.contribution) * 10000);
        this.sum5Target.innerHTML = '$' + this.numberWithCommas(sum5);

        // update chart
        let data = [];
        for (let i=1; i<=6; i++) {
            let v = Math.abs(eval('s'+i+'_stats').amount_save) / 1000;
            v = Math.round( v * 100 + Number.EPSILON ) / 100;
            //console.log(v)
            data.push(v);
        }
        //console.log(data)
        this.chart.data.datasets[0].data = data;
        this.chart.update();

        this.totalInputTarget.value = income;

        // show hide nyc when necessary
        if (this.state === 'ny' && this.is_open) this.detailNyTarget.classList.remove('hidden');
        else this.detailNyTarget.classList.add('hidden');
    }

    numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    updateStats(scenario, stats) {
        this['sceSetTargets'][scenario].innerHTML = '$' + this.numberWithCommas(stats.se_tax);
        this['sceFitTargets'][scenario].innerHTML = '$' + this.numberWithCommas(stats.fit);
        this['sceAmtTargets'][scenario].innerHTML = '$' + this.numberWithCommas(stats.amt);
        this['sceNiitTargets'][scenario].innerHTML = '$' + this.numberWithCommas(stats.niit);
        this['sceCatTargets'][scenario].innerHTML = '$' + this.numberWithCommas(stats.cat);
        this['sceNytTargets'][scenario].innerHTML = '$' + this.numberWithCommas(stats.nyt);
        this['sceOtherTargets'][scenario].innerHTML = '$' + this.numberWithCommas(stats.other_tax);
        this['sceCorpTargets'][scenario].innerHTML = '$' + this.numberWithCommas(stats.corp_fee);

        this['sceTotalTopTargets'][scenario].innerHTML = '$' + this.numberWithCommas(stats.total_tax);
        this['sceTotalTargets'][scenario].innerHTML = '$' + this.numberWithCommas(stats.total_tax);

        // this['sceTotalTargets'][scenario].forEach((ele)=>{
        //     ele.innerHTML = '$' + this.numberWithCommas(stats.total_tax);
        // })

        this['sceContTargets'][scenario].innerHTML = '$' + this.numberWithCommas(stats.contribution);
        this['sceDisTargets'][scenario].innerHTML = '$' + this.numberWithCommas(stats.dis_income);
        this['sceAmountSaveTargets'][scenario].innerHTML = '$' + this.numberWithCommas(stats.amount_save);
        this['scePercentSaveTargets'][scenario].innerHTML = this.numberWithCommas(stats.percent_save) + '%';
    }
}
